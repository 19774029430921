const version = {
    major: 1,
    minor: 0,
    fix: 0,
  };
  
  const __APP_TITLE__ = "Altın Metre - Yönetim Paneli";
  const __APP_DESCRIPTION__ = "...";
  const __APP_VERSION__ = `${version.major}.${version.minor}.${version.fix}`;
  const __APP_DATE__ = "11.01.2024";
  const __APP_COPYRIGHT__ = "BemSoft © 2024";
  const __APP_VENDOR_URL__ = "https://altinmetre.com/";
  const __APP_VENDOR_URL_TITLE__ = "altinmetre.com";
  
  const __BASE_URL__ = "/";
  
  export default {
    APP_TITLE: __APP_TITLE__,
    APP_DESCRIPTION: __APP_DESCRIPTION__,
    APP_VERSION: __APP_VERSION__,
    APP_DATE: __APP_DATE__,
    APP_COPYRIGHT: __APP_COPYRIGHT__,
    APP_VENDOR_URL: __APP_VENDOR_URL__,
    APP_VENDOR_URL_TITLE: __APP_VENDOR_URL_TITLE__,
  
    BASE_URL: __BASE_URL__,
  };
  