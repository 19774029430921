import { apiService } from '../../services/api';

const state = {
  accessToken: null,
  refreshToken: null,
};

const mutations = {
  setToken: (state, payload) => {
    console.log(payload);
    if (payload?.access_token) {
      localStorage.setItem('accessToken', payload?.access_token);
      if (payload?.user) {
        localStorage.setItem(
          'nickName',
          `${payload?.user?.firstName} ${payload?.user?.lastName}`,
        );
      }
    }
    state.accessToken = payload?.access_token || null;
  },
};

const actions = {
  async login({ commit }, payload) {
    apiService.post(
      'auth/login',
      { ...payload.form },
      (response) => {
        commit('setToken', response?.data);
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async logOut({ commit }) {
    localStorage.removeItem('accessToken');
    commit('setToken', null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
