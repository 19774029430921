import Vue from 'vue';
import Vuex from 'vuex';

// begin::Modules
import statistic from './modules/statistic';
import users from './modules/users';
import seller from './modules/seller';
import auth from './modules/auth';
import product from './modules/product';
import forum from './modules/forum';
import currency from './modules/currency';
// end::Modules

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    statistic,
    users,
    seller,
    auth,
    product,
    currency,
    forum,
  },
});
