import { apiService } from '../../services/api';

const state = {};

const mutations = {};

const actions = {
  async saveSeller({ commit }, payload) {
    apiService.post(
      'sellers',
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async updateSeller({ commit }, payload) {
    apiService.put(
      'sellers/' + payload.id,
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async updateSellerProduct({ commit }, payload) {
    apiService.put(
      'seller-products/' + payload.id,
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getSellers({ commit }, payload) {
    apiService.get(
      'sellers?page=' + payload.page,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getSellerProducts({ commit }, payload) {
    apiService.get(
      'seller-products/seller/' + payload.id,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async saveSellerProduct({ commit }, payload) {
    apiService.post(
      'seller-products',
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getSellerProduct({ commit }, payload) {
    apiService.get(
      'seller-products/' + payload.id,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getSeller({ commit }, payload) {
    apiService.get(
      'sellers/' + payload.id,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
