import { apiService } from '../../services/api';

const state = {};

const mutations = {};

const actions = {
  async totalStatistic({ commit }, payload) {
    apiService.get(
      'stats',
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
