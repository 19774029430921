import { apiService } from '@/services/api';

const state = {};

const mutations = {};

const actions = {
  async getList({ commit }, payload) {
    apiService.get(
      'currencies?page=' + payload.page,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async update({ commit }, payload) {
    apiService.put(
      `currencies/${payload.id}`,
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getById({ commit }, payload) {
    apiService.get(
      'currencies/' + payload.id,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
