export default {
  path: "products",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Product/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        product: () => import("../../views/Home/views/Product/List/Index"),
      },
    },
    {
      path: ":id",
      meta: {},
      components: {
        product: () => import("../../views/Home/views/Product/Update/Index"),
      },
    },
  ],
};
