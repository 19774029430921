import { apiService } from '../../services/api';

const state = {};

const mutations = {};

const actions = {
  async saveForumCategory({ commit }, payload) {
    apiService.post(
      'forum-category',
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async updateForumCategory({ commit }, payload) {
    apiService.put(
      'forum-category/' + payload.id,
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },

  async getForumCategories({ commit }, payload) {
    apiService.get(
      'forum-category?page=' + payload.page,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getChildCategories({ commit }, payload) {
    apiService.get(
      'forum-category/load/child' + (payload?.id ? `/${payload.id}` : ''),
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getForumCategory({ commit }, payload) {
    const token = localStorage.getItem('accessToken') ?? null;
    apiService.get(
      'forum-category/' + payload.id,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
