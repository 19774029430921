export default {
  path: "sellers",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Seller/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        seller: () => import("../../views/Home/views/Seller/List/Index"),
      },
    },
    {
      path: ":id",
      meta: {},
      components: {
        seller: () => import("../../views/Home/views/Seller/Update/Index"),
      },
    },
    {
      path: "product/:id",
      meta: {},
      components: {
        seller: () => import("../../views/Home/views/Seller/Update/SellerProduct/Index"),
      },
    },
  ],
};
