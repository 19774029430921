export default {
  path: "currencies",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Currency/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        currency: () => import("../../views/Home/views/Currency/List/Index"),
      },
    },
    {
      path: ":id",
      meta: {},
      components: {
        currency: () => import("../../views/Home/views/Currency/Update/Index"),
      },
    },
  ],
};
