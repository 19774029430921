import { apiService } from '../../services/api';

const state = {};

const mutations = {};

const actions = {
  async getProducts({ commit }, payload) {
    apiService.get(
      'products',
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getProduct({ commit }, payload) {
    apiService.get(
      'products/' + payload.id,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async getProductsPaginate({ commit }, payload) {
    apiService.get(
      'products?page=' + payload.page,
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async updateProductSeller({ commit }, payload) {
    apiService.post(
      `products/seller/${payload.productId}/${payload.sellerId}/${payload.isActive}`,
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
  async updateProduct({ commit }, payload) {
    apiService.put(
      `products/${payload.id}`,
      { ...payload.form },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      },
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
