import Vue from 'vue';
import VueRouter from 'vue-router';
import constant from '../plugins/globalVariables';
import seller from './routes/seller';
import forum from './routes/forum';
import product from './routes/product';
import currency from './routes/currency';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../views/Login/Index'),
  },
  {
    path: '',
    component: () => import('../views/Home/Index'),
    children: [
      {
        name: 'home',
        path: '',
        components: {
          home: () => import('../views/Home/views/Dashboard/Index'),
        },
      },
      seller,
      forum,
      product,
      currency,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: constant.BASE_URL,
  routes: routes,
});

const documentTitle = `${constant.APP_TITLE} | v${constant.APP_VERSION} (${constant.APP_DATE})`;
const authRoutes = ['login'];

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || documentTitle;

  if (localStorage.getItem('accessToken')) {
    if (to.name == 'login') {
      router.replace('/').catch(() => {});
    } else {
      next();
    }
  } else {
    if (!authRoutes.includes(to.name)) {
      router.replace({ name: 'login' }).catch(() => {});
    } else {
      next();
    }
  }
});

export default router;
