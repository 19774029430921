export default {
  path: "forum",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/Forum/Index"),
  },
  children: [
    {
      path: "",
      meta: {},
      components: {
        forum: () => import("../../views/Home/views/Forum/List/Index"),
      },
    },
    {
      path: "category",
      meta: {},
      components: {
        forum: () => import("../../views/Home/views/Forum/Category/Index"),
      },
    },
    {
      path: "category/:id",
      meta: {},
      components: {
        forum: () => import("../../views/Home/views/Forum/Category/Update/Index"),
      },
    },
    // {
    //   path: ":id",
    //   meta: {},
    //   components: {
    //     seller: () => import("../../views/Home/views/Seller/Update/Index"),
    //   },
    // },
    // {
    //   path: "product/:id",
    //   meta: {},
    //   components: {
    //     seller: () => import("../../views/Home/views/Seller/Update/SellerProduct/Index"),
    //   },
    // },
  ],
};
